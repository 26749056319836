import { Coordinate, EmbeddedUser } from "types";

/**
* function to make sure the iframe has loaded successfully.
* @return
*/

export function ping(callback?: (message: any) => void) {
  return {
    cmd: 'Ping'
  }
}

/**
* function to start an automatic solar design process in the iframe window.
* @param {Coordinate} coordinate the latitude and longitude
* @param {number} usage amount of electricity
* @return
*/

export function generate(coordinate: Coordinate, usage: number, user: EmbeddedUser) {
  // HACK: this is a hack to make sure the iframe has loaded successfully.

  const convertRoofAge = (value: string | undefined): number => {
    switch (value) {
      case '0-5':
        return 5;
      case '5-10':
        return 10;
      case '10-15':
        return 15;
      case '15+':
        return 20;
      default:
        return 5;
    }
  }

  return {
    cmd: 'generate',
    params: {
      coordinates: `${coordinate.lat},${coordinate.lng}`,
      electricity_usage: usage,
      contacts: {
        first_name: user?.quote?.firstName ?? "Unknown",
        last_name: user?.quote?.lastName ?? "Unknown",
        email: user?.quote?.email ?? "test@example.com",
        phone: user?.quote?.phone ?? "+1 (000) 000-000"
      },
      monthly_bill: Number(user?.details?.bill ?? 0),
      property_profile: {
        roof_age: convertRoofAge(user?.details?.age),
        roof_type: user?.details?.type || "",
      }
    }
  }
}

/**
* function to load an old solar design in the iframe window
* @param {string} uid the lead id
* @param {string} pid the proposal id
* @param {Function} callback callback method to send message to the iframe
* @return 
*/

export function loadProposal(uid: string, pid: string) {
  return {
    cmd: 'proposal.LoadProposal',
    params: { uid, pid },
    config: {
      defaultBtnView: {
        ShadingSimulator: true,
        Ground: false,
        Trees: true,
        FireSetbacks: true,
        Panels: false,
        House: true,
        ShadingGradients: true,
        DSM: true,
        HouseDSM: true,
      },
    }
  }
}